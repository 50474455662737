'use client'
import React from 'react';
import { useState, useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { ChevronDown, Menu, X } from 'lucide-react'

export default function LandingPage() {
  const [selectedPackage, setSelectedPackage] = useState<string | null>(null)
  const [showComparison, setShowComparison] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const packages = [
    {
      name: 'Starter',
      price: '$99/month',
      features: ['AI-powered influencer matching', '10 content posts per month', 'Basic analytics'],
    },
    {
      name: 'Pro',
      price: '$299/month',
      features: ['Advanced AI matching', 'Unlimited content posts', 'Detailed analytics', 'Priority support'],
    },
    {
      name: 'Enterprise',
      price: 'From $499/month',
      features: ['Custom AI algorithms', 'Unlimited content & influencers', 'Advanced API access', 'Dedicated manager'],
    },
  ]

  return (
    <div className="min-h-screen text-gray-900 overflow-hidden bg-white">
      <div className="fixed inset-0 z-0">
        <div className="absolute inset-0 bg-gradient-to-r from-transparent via-blue-50 to-transparent animate-subtle-gloss"></div>
      </div>
      <div className="relative z-10 font-sans">
        <header className="sticky top-0 bg-white/80 backdrop-blur-md border-b border-gray-200">
          <nav className="container mx-auto px-4 sm:px-6 py-4 flex justify-between items-center">
            <h1 className="text-2xl font-light">hAIp</h1>
            <div className="hidden md:flex space-x-6 text-sm">
              <a href="#features" className="text-gray-600 hover:text-gray-900 transition-colors">Features</a>
              <a href="#pricing" className="text-gray-600 hover:text-gray-900 transition-colors">Pricing</a>
              <a href="#contact" className="text-gray-600 hover:text-gray-900 transition-colors">Contact</a>
            </div>
            <button className="md:hidden" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
              {mobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </nav>
          {mobileMenuOpen && (
            <div className="md:hidden bg-white">
              <a href="#features" className="block px-4 py-2 text-gray-600 hover:bg-gray-100" onClick={() => setMobileMenuOpen(false)}>Features</a>
              <a href="#pricing" className="block px-4 py-2 text-gray-600 hover:bg-gray-100" onClick={() => setMobileMenuOpen(false)}>Pricing</a>
              <a href="#contact" className="block px-4 py-2 text-gray-600 hover:bg-gray-100" onClick={() => setMobileMenuOpen(false)}>Contact</a>
            </div>
          )}
        </header>

        <main className="container mx-auto px-4 sm:px-6">
          <section className="py-12 sm:py-24 text-center">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-4xl sm:text-5xl font-light mb-6 tracking-tight"
            >
              Revolutionize Your Influencer Marketing
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="text-lg sm:text-xl mb-12 max-w-2xl mx-auto font-light text-gray-600"
            >
              Harness the power of AI to find the perfect influencers and post content instantly.
            </motion.p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-black text-white px-8 py-3 rounded-full text-lg font-light hover:bg-gray-900 transition-colors shadow-lg hover:shadow-xl"
              style={{
                background: 'linear-gradient(145deg, #000000, #222222)',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
              }}
            >
              Get Started
            </motion.button>
          </section>

          <section id="pricing" className="py-12 sm:py-24">
            <h3 className="text-2xl sm:text-3xl font-light text-center mb-12">Choose Your Plan</h3>
            <div className="grid md:grid-cols-3 gap-8">
              {packages.map((pkg) => (
                <motion.div
                  key={pkg.name}
                  whileHover={{ scale: 1.03 }}
                  className="bg-white p-6 sm:p-8 rounded-2xl shadow-lg cursor-pointer border border-gray-100"
                  onClick={() => setSelectedPackage(pkg.name)}
                >
                  <h4 className="text-xl sm:text-2xl font-light mb-4">{pkg.name}</h4>
                  <p className="text-3xl sm:text-4xl font-light mb-6">{pkg.price}</p>
                  <ul className="space-y-2 mb-8">
                    {pkg.features.map((feature) => (
                      <li key={feature} className="flex items-center text-sm text-gray-600">
                        <svg className="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                        {feature}
                      </li>
                    ))}
                  </ul>
                  <motion.button
                    whileTap={{ scale: 0.95 }}
                    className={`w-full py-2 rounded-full text-sm transition-colors ${
                      selectedPackage === pkg.name
                        ? 'bg-black text-white'
                        : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                    }`}
                  >
                    {selectedPackage === pkg.name ? 'Selected' : 'Select Plan'}
                  </motion.button>
                </motion.div>
              ))}
            </div>
          </section>

          <section className="py-12 sm:py-24 text-center">
            <h3 className="text-2xl sm:text-3xl font-light mb-8">Compare Features</h3>
            <motion.button
              onClick={() => setShowComparison(!showComparison)}
              className="flex items-center mx-auto bg-gray-100 px-6 py-3 rounded-full text-sm font-light hover:bg-gray-200 transition-colors"
            >
              {showComparison ? 'Hide Comparison' : 'Show Comparison'}
              <ChevronDown className={`ml-2 transform transition-transform ${showComparison ? 'rotate-180' : ''}`} />
            </motion.button>
            {showComparison && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                className="mt-8 overflow-x-auto"
              >
                <table className="w-full border-collapse bg-white rounded-2xl overflow-hidden shadow-lg">
                  <thead>
                    <tr className="border-b border-gray-200">
                      <th className="p-4 text-left font-light">Feature</th>
                      <th className="p-4 text-left font-light">Starter</th>
                      <th className="p-4 text-left font-light">Pro</th>
                      <th className="p-4 text-left font-light">Enterprise</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-b border-gray-100">
                      <td className="p-4">AI Matching</td>
                      <td className="p-4">Basic</td>
                      <td className="p-4">Advanced</td>
                      <td className="p-4">Custom</td>
                    </tr>
                    <tr className="border-b border-gray-100">
                      <td className="p-4">Content Posts</td>
                      <td className="p-4">10/month</td>
                      <td className="p-4">Unlimited</td>
                      <td className="p-4">Unlimited</td>
                    </tr>
                    <tr className="border-b border-gray-100">
                      <td className="p-4">Analytics</td>
                      <td className="p-4">Basic</td>
                      <td className="p-4">Detailed</td>
                      <td className="p-4">Advanced</td>
                    </tr>
                    <tr>
                      <td className="p-4">Support</td>
                      <td className="p-4">Email</td>
                      <td className="p-4">Priority</td>
                      <td className="p-4">Dedicated</td>
                    </tr>
                  </tbody>
                </table>
              </motion.div>
            )}
          </section>

          <section id="features" className="py-12 sm:py-24">
            <h3 className="text-2xl sm:text-3xl font-light text-center mb-12">How Our AI Finds the Right Influencers</h3>
            <div className="grid md:grid-cols-3 gap-8">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="text-center bg-white p-6 rounded-2xl shadow-lg border border-gray-100"
              >
                <h4 className="text-xl font-light mb-4">Data Analysis</h4>
                <p className="text-sm text-gray-600">Our AI analyzes millions of data points to understand influencer performance and audience engagement.</p>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                className="text-center bg-white p-6 rounded-2xl shadow-lg border border-gray-100"
              >
                <h4 className="text-xl font-light mb-4">Brand Matching</h4>
                <p className="text-sm text-gray-600">We use advanced algorithms to match influencers with your brand's values, style, and target audience.</p>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
                className="text-center bg-white p-6 rounded-2xl shadow-lg border border-gray-100"
              >
                <h4 className="text-xl font-light mb-4">Predictive Insights</h4>
                <p className="text-sm text-gray-600">Our AI predicts campaign performance and suggests optimizations for maximum ROI.</p>
              </motion.div>
            </div>
          </section>

          <section id="contact" className="py-12 sm:py-24">
            <h3 className="text-2xl sm:text-3xl font-light text-center mb-12">Instant Content Posting</h3>
            <div className="max-w-2xl mx-auto text-center bg-white p-6 sm:p-8 rounded-2xl shadow-lg border border-gray-100">
              <p className="text-sm mb-8 text-gray-600">
                Our platform allows you to post content within influencer profiles instantly, streamlining your marketing campaigns and improving time-to-market.
              </p>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-black text-white px-8 py-3 rounded-full text-sm font-light hover:bg-gray-900 transition-colors shadow-lg hover:shadow-xl"
                style={{
                  background: 'linear-gradient(145deg, #000000, #222222)',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
                }}
              >
                Try Instant Posting
              </motion.button>
            </div>
          </section>
        </main>

        <footer className="bg-gray-50 py-8 sm:py-12 border-t border-gray-100">
          <div className="container mx-auto px-4 sm:px-6 text-center text-sm font-light text-gray-600">
            <p>&copy; 2024 hAIp. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </div>
  )
}